import { tns } from 'tiny-slider';

const realisationsSlider = document.querySelector('.tns-realisations');
if (realisationsSlider) {
    new tns({
        container: realisationsSlider,
        nav: false,
        rewind: true,
        controls: false,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayButtonOutput: false,
        gutter: 40,
        edgePadding: 40,
        items: 1,
        responsive: {
            400: {
                items: 2,
            },
            992: {
                items: 3,
            },
            1400: {
                items: 4,
            },
        },
    });
}
